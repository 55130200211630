import { forwardRef, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styles from './styles.module.css';

const CustomDateInput = forwardRef(({
    value,
    dayStart,
    monthStart,
    yearStart,
    dayEnd,
    monthEnd,
    yearEnd,
    onChange,
    disabled,
    validate,
    onValidate,
    requiredDateMessage,
    exceededDateMessage,
    min,
    max,
    autoFocus,
    width,
    inputSize,
}, ref) => {
    const dayInputRef = useRef();
    const monthInputRef = useRef();
    const yearInputRef = useRef();
    const [isChanged, setIsChanged] = useState(false);
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [changeTimes, setChangeTimes] = useState(0);
    const [isDateInvalid, setIsDateInvalid] = useState(false);
    const shouldValidate = typeof onValidate === 'function' && validate;

    // Restricting characters that is not convertable to number
    const getNumbersOnly = (string) => {
        const stringArray = string.split('');
        const result = stringArray.filter((char) => (
            !isNaN(char) && !isNaN(parseFloat(char))
        ));
        return result.join('');
    }

    // Change cursor
    const focusToDay = () => {
        setTimeout(() => {
            dayInputRef.current.focus();
            dayInputRef.current.select();
        }, 0);
    };

    const focusToMonth = () => {
        setTimeout(() => {
            monthInputRef.current.focus();
            monthInputRef.current.select();
        }, 0);
    };

    const focusToYear = () => {
        setTimeout(() => {
            yearInputRef.current.focus();
            yearInputRef.current.select();
        }, 0);
    };

    const handleOnFocus = (e) => e.target.select();

    const handleOnClick = (e) => {
        e.stopPropagation();
        const name = e.target.name;
        if (name === 'day') focusToDay();
        if (name === 'month') focusToMonth();
        if (name === 'year') focusToYear();
    }

    const handleOnBlur = (e) => 
    {
        if(e.target.name === "year")
        {
            if(!isLeapYear(e.target.value) && month === "02")
            {
                const lastDayOfTheMonth = getLastDayOfTheMonth(parseInt("02"));
            if (day > lastDayOfTheMonth) setDay(lastDayOfTheMonth);
            }
            if(isLeapYear(e.target.value) && month === "02"){
               if(parseInt(day) > 29)
               {
                setDay("29");
               }
            }

            if(month !== "02")
            {
                const lastDayOfTheMonth = getLastDayOfTheMonth(month);
                if (day > lastDayOfTheMonth) setDay(lastDayOfTheMonth);
            }
        }

        setChangeTimes(0);
    }

    // Checking and setting values
    const isTwoDigit = (value) => value.length === 3;

    const isFourDigit = (value) => value.length === 5;

    const getLastDayOfTheMonth = (monthIndex) => {
        const dateToday = new Date(new Date().getFullYear(), monthIndex, 0);
        return dateToday.getDate();
    };

    const padDayMonth = (value) => {
        if (value.toString().length === 1) return `0${value}`;
        else if (value[0] === '0') return value.substring(1, 3);
        else return value;
    };

    const padYear = (value) => {
        if (value.length === 1) return `000${value}`;
        else if (value.length === 2) return `00${value}`;
        else if (value.length === 3) return `0${value}`;
        else if (value.length === 5 && value[0] === '0') return value.substring(1, 5);
        else return value;
    };

    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      }
      
    const isValueValid = (value, inputName) => {
        const number = parseInt(value);
        if (inputName === 'day') {
            let lastDay = month ? getLastDayOfTheMonth(parseInt(month)) : 31;

            if(parseInt(month) === 2 && isLeapYear(year))
            {
                lastDay = lastDay + 1;
            }
            return number <= lastDay;
        }
        if (inputName === 'month') return number <= 12;
        return true;
    };

    const handleOnChange = (e) => {        
        const name = e.target.name;
        const isNotYear = ['day', 'month'].includes(name);
        let value = getNumbersOnly(e.target.value);
        if (isNotYear) value = padDayMonth(value);
        else value = padYear(value);
        const isFirstDigitZero = value[0] === '0';
        const isNotValid = value && !isValueValid(value, name);

        if ((changeTimes === 1 || value.length === 3) && (value.length === 2 || isNotValid)) {
            if (name === 'day') focusToMonth();
            else focusToYear();
        }
        
        if (isNotYear) {
            if (!isFirstDigitZero && (isTwoDigit(value) || isNotValid)) return false;
        } else {
            if (!isFirstDigitZero && (isFourDigit(value) || isNotValid)) return false;
        }

        if (name === 'day') {
            setDay(value);
        } else if (name === 'month') {
            setMonth(value);
        } else {
            setYear(value);
        }
        setChangeTimes(changeTimes + 1);
        setIsChanged(true);
    };

    const constructDate = (value) => {
        const date = new Date(value);
        const dateValue = date.getDate();
        const monthValue = date.getMonth() + 1;
        const yearValue = date.getFullYear();
        const padDateValue = padDayMonth(dateValue);
        const padMonthValue = padDayMonth(monthValue);
        const padYearValue = padYear(yearValue);
        setDay(padDateValue);
        setMonth(padMonthValue);
        setYear(padYearValue);
    };

    const checkIfBeforeMin = (date) => {
        const minDate = new Date(min);
        minDate.setHours(0);
        minDate.setMinutes(0);
        minDate.setSeconds(0);
        minDate.setMilliseconds(0);
        return date < minDate;
    }

    const checkIfAfterMax = (date) => {
        const maxDate = new Date(max);
        maxDate.setHours(0);
        maxDate.setMinutes(0);
        maxDate.setSeconds(0);
        maxDate.setMilliseconds(0);
        return date > maxDate;
    }

    const checkPeriod = (date) => {
        const isBeforeMin = min && checkIfBeforeMin(date);
        const isAfterMax = max && checkIfAfterMax(date);
        return isBeforeMin || isAfterMax;
    };

    // Keyboard arrow navigations
    const onKeyDownDay = (e) => {
        if (e.key === 'ArrowRight') focusToMonth();
        if (e.key === 'ArrowUp') {
            let incremented = parseInt(day) + 1;
            if (incremented <= getLastDayOfTheMonth(month)) setDay(padDayMonth(incremented.toString()));
        }
        if (e.key === 'ArrowDown') {
            let decremented = parseInt(day) - 1;
            if (decremented >= 1) setDay(padDayMonth(decremented.toString()));
        }
        if (e.key === 'Backspace') {
            setDay('');
            setChangeTimes(0);
            setIsChanged(true);
        }
    };

    const onKeyDownMonth = (e) => {
        if (e.key === 'ArrowRight') focusToYear();
        if (e.key === 'ArrowLeft') focusToDay();
        if (e.key === 'ArrowUp') {
            let incremented = parseInt(month) + 1;
            if (incremented <= 12) setMonth(padDayMonth(incremented.toString()));
        }
        if (e.key === 'ArrowDown') {
            let decremented = parseInt(month) - 1;
            const lastDayOfTheMonth = getLastDayOfTheMonth(decremented);
            if (decremented >= 1) setMonth(padDayMonth(decremented.toString()));
            if (day > lastDayOfTheMonth) setDay(lastDayOfTheMonth);
        }
        if (e.key === 'Backspace') {
            setMonth('');
            setChangeTimes(0);
            setIsChanged(true);
        }
    };

    const onKeyDownYear = (e) => {
        if (e.key === 'ArrowLeft') focusToMonth();
        if (e.key === 'ArrowUp') {
            let incremented = parseInt(year) + 1;
            if (incremented <= 9999) setYear(padYear(incremented.toString()));
        }
        if (e.key === 'ArrowDown') {
            let decremented = parseInt(year) - 1;
            if (decremented >= 0) setYear(padYear(decremented.toString()));
        }
        if (e.key === 'Backspace') {
            setYear('');
            setIsChanged(true);
        }
    };

    const getMonthInputWidth = (month) => {
        const value = month.toString();
        if (value === '11') return '10px';
        if (['01', '10'].includes(value)) return '13px';
        if (value === '07') return '16px';
        if (value.includes('0')) return '17px';
        if (value === '12') return '13px';
        return '19px';
    };

    useEffect(() => {
        if ((value && !isChanged) || !day || !month || !year) {
            const isValidDate = value && moment(value).isValid();
            if (isValidDate)
            {
                constructDate(value);
            } 
            else {
                dayStart && setDay(dayStart);
                dayEnd && setDay(dayEnd);
                monthStart && setMonth(monthStart);
                monthEnd && setMonth(monthEnd);
                yearStart && setYear(yearStart);
                yearEnd && setYear(yearEnd);
            }
        }
    }, [value]);

    useEffect(() => {
        if (isChanged) {
            if (day && month && year && day !== '00' && month !== '00' && year !== '0000') {
                const date = new Date(
                    parseInt(year),
                    parseInt(month) - 1,
                    parseInt(day),
                    0,
                    0,
                    0,
                    0,
                );
                if (typeof onChange === 'function') 
                {
                    onChange(date);
                }
                const state = checkPeriod(date);
                setIsDateInvalid(state);
                if (typeof onValidate === 'function') onValidate(state);
            } else {
                if (typeof onChange === 'function')
                {
                    onChange(null, day, month, year);
                } 
            }
        }
    }, [day, month, year]);

    useEffect(() => {
        if (autoFocus) focusToDay();
    }, [autoFocus]);

    return (
        <div className={styles.dateInput} style={{ width }}>
            <div
                ref={ref}
                className={`${styles.dateInputWrapper} ${(shouldValidate && (!value || isDateInvalid)) && !disabled ? styles.invalidInput : ''} ${disabled ? styles.disabledInput : ''} ${inputSize === 'lg' ? styles.lgDateInput : ''}`}
                style={{ width }}
                onClick={focusToDay}
            >
                <input
                    ref={dayInputRef}
                    className={styles.dayInput}
                    style={{ marginLeft: day && '-5px' }}
                    value={day}
                    name="day"
                    type="text"
                    placeholder="DD"
                    onChange={handleOnChange}
                    onKeyDown={onKeyDownDay}
                    onFocus={handleOnFocus}
                    onClick={handleOnClick}
                    onBlur={handleOnBlur}
                    disabled={disabled}
                    autoComplete="off"
                />
                <span className={styles.inputDivider}>/</span>
                <input
                    ref={monthInputRef}
                    className={styles.monthInput}
                    value={month}
                    name="month"
                    type="text"
                    placeholder="MM"
                    onChange={handleOnChange}
                    onKeyDown={onKeyDownMonth}
                    onFocus={handleOnFocus}
                    onClick={handleOnClick}
                    onBlur={handleOnBlur}
                    disabled={disabled}
                    autoComplete="off"
                    style={{ width: getMonthInputWidth(month)}}
                />
                <span className={styles.inputDivider}>/</span>
                <input
                    ref={yearInputRef}
                    className={styles.yearInput}
                    value={year}
                    name="year"
                    type="text"
                    placeholder="YYYY"
                    onChange={handleOnChange}
                    onKeyDown={onKeyDownYear}
                    onFocus={handleOnFocus}
                    onClick={handleOnClick}
                    onBlur={handleOnBlur}
                    disabled={disabled}
                    autoComplete="off"
                />
            </div>
            {!value && !disabled && shouldValidate && (
                <span className={styles.errorMessage}>{requiredDateMessage || 'Date is required'}</span>
            )}
            {isDateInvalid && value && !disabled && shouldValidate && (
                <span className={styles.errorMessage}>{exceededDateMessage || 'Date exceeded date period'}</span>
            )}
        </div>
    )
});

export default CustomDateInput;
