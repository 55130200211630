import React from 'react';
import PropTypes from 'prop-types';
import Styles from './GeneratedScheduleBanner.module.css';
import CustomDateRangeInput from 'shared/components/custom-date-range/CustomDateRangeInput';

function GeneratedScheduleBannerComponent({
  componentName,
  scheduleDetails,
  dateRange,
  callbackChangeDateRange,
  scheduleID,
}) {
  return (
    <div className={`${Styles.header} position-relative`}>
      <b className={Styles.headerText}>{componentName}</b>
      <div className="d-flex position-absolute end-0">
        <div className={`row ${Styles.marginBottom}`}>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Channel:</div>
          </div>
          <div className={Styles.divHeaderPadding}>
            <strong className={Styles.channelLabel}>
              {scheduleDetails.channelName}
            </strong>
          </div>

          {scheduleID === '' ? (
            <div
              className={`
              ${Styles.divHeaderPadding} 
              ${Styles.paddingTopNone} 
              ${Styles.customDateInput}`}
              style={{ marginTop: '-12px' }}
            >
              <CustomDateRangeInput
                onChange={callbackChangeDateRange}
                inputSize={'lg'}
                startSettings={{
                  value: dateRange.start,
                  label: 'Period from:',
                  labelLeft: true,
                  width: '155px',
                  color: '#6d6d73',
                }}
                endSettings={{
                  value: dateRange.end,
                  label: 'To:',
                  labelLeft: true,
                  width: '155px',
                  color: '#6d6d73',
                }}
              />
            </div>
          ) : (
            <>
              <div className={Styles.divHeaderPadding}>
                <div className={Styles.channelLabel}>Period:</div>
              </div>
              <div className={Styles.divHeaderPadding}>
                <strong className={Styles.channelLabel}>
                  {scheduleDetails.period}
                </strong>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
GeneratedScheduleBannerComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  scheduleDetails: PropTypes.shape(),
};
export default GeneratedScheduleBannerComponent;
