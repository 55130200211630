import { forwardRef, useEffect, useRef, useState } from 'react';
import CustomDateInput from '../custom-date-input/CustomDateInput';
import styles from './styles.module.css';
import { NavItem } from 'react-bootstrap';

const CustomDateRangeInput = forwardRef(({
    startSettings = {
        value: null,
        label: '',
        labelLeft: false,
        disabled: false,
        required: false,
        width: null,
        color: 'black',
    },
    endSettings = {
        value: null,
        label: '',
        labelLeft: false,
        disabled: false,
        required: false,
        width: null,
        color: 'black',
    },
    min,
    max,
    validate,
    onValidate,
    onChange,
    invalidDateMessage,
    inputSize,
}, ref) => {
    const startRef = useRef();
    const endRef = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dayStart, setDayStart] = useState(null);
    const [dayEnd, setDayEnd] = useState(null);
    const [monthStart, setMonthStart] = useState(null);
    const [monthEnd, setMonthEnd] = useState(null);
    const [yearStart, setYearStart] = useState(null);
    const [yearEnd, setYearEnd] = useState(null);
    const [isDateInvalid, setIsDateInvalid] = useState(false);
    const shouldValidate = typeof onValidate === 'function' && validate;
    const invalidDateRange = isDateInvalid && !startSettings.disabled && !endSettings.disabled && shouldValidate;
    const invalidStartDate = !startSettings.disabled && !startDate && startSettings.required && shouldValidate;
    const invalidEndDate = !endSettings.disabled && !endDate && endSettings.required && shouldValidate;

    // Setting values
    const handleOnChange = (name, value, day, month, year) => {
        if (name === 'start') setStartDate(value);
        else setEndDate(value);

        if (typeof onChange === 'function') {
            onChange({
                start: name === 'start' ? value : startDate,
                end: name === 'end' ? value : endDate,
                dayStart: name === 'start' ? day : dayStart,
                monthStart: name === 'start' ? month : monthStart,
                yearStart: name === 'start' ? year : yearStart,
                end: name === 'end' ? value : endDate,
                dayEnd: name === 'end' ? day : dayEnd,
                monthEnd: name === 'end' ? month : monthEnd,
                yearEnd: name === 'end' ? year : yearEnd,
            })
        }
    };

    // Check dates
    const isDateRangeInvalid = () => {
        return (startDate && ((min && startDate < min) || (max && startDate > max))) // compare start to min and max
            || (startDate && endDate && startDate > endDate) // compare start to end
            || (endDate && max && (endDate < min || endDate > max)); // compare end to min and max
    };

    useEffect(() => {
        const isInvalid = isDateRangeInvalid();
        setIsDateInvalid(isInvalid);
        if (shouldValidate) onValidate(isInvalid);
    }, [startDate, endDate]);

    useEffect(() => {
        setStartDate(startSettings.value);
        setEndDate(endSettings.value);
        setDayStart(startSettings.dayStart);
        setDayEnd(endSettings.dayEnd);
        setMonthStart(startSettings.monthStart);
        setMonthEnd(endSettings.monthEnd);
        setYearStart(startSettings.yearStart);
        setYearEnd(endSettings.yearEnd);
    }, [startSettings, endSettings]);

    return (
        <div className={styles.dateRange}>
            <div ref={ref} className={styles.dateRangeWrapper}>
                <div
                    className={`${styles.startDateWrapper} ${startSettings.labelLeft && styles.labelLeft}`}
                >
                    {startSettings.label !== null && (
                        <span
                            className={styles.label}
                            style={{ marginTop: inputSize === 'lg' && startSettings.labelLeft ? '14px' : null, color: startSettings.color }}
                        >
                            {startSettings.label || 'Start'}
                        </span>
                    )}
                    <div className={styles.dateInput}>
                        <CustomDateInput
                            ref={startRef}
                            value={startSettings.value}
                            dayStart={startSettings.dayStart}
                            monthStart={startSettings.monthStart}
                            yearStart={startSettings.yearStart}
                            min={min}
                            max={endDate}
                            onChange={(value, day, month, year) => handleOnChange('start', value, day, month, year)}
                            validate={invalidStartDate}
                            disabled={startSettings.disabled}
                            width={startSettings.width}
                            inputSize={inputSize}
                        />
                    </div>
                </div>
                {!endSettings.labelLeft && (
                    <div className={styles.spaceBetween}></div>
                )}
                <div
                    className={`${styles.endDateWrapper} ${endSettings.labelLeft && styles.labelLeft}`}
                >
                    {endSettings.label !== null && (
                        <span
                            className={styles.label}
                            style={{ marginTop: inputSize === 'lg' && endSettings.labelLeft ? '14px' : null, color: endSettings.color }}
                        >
                            {endSettings.label || 'End'}
                        </span>
                    )}
                    <div className={styles.dateInput}>
                        <CustomDateInput
                            ref={endRef}
                            value={endSettings.value}
                            dayEnd={endSettings.dayEnd}
                            monthEnd={endSettings.monthEnd}
                            yearEnd={endSettings.yearEnd}
                            min={startDate}
                            max={max}
                            onChange={(value, day, month, year) => handleOnChange('end', value, day, month, year)}
                            validate={invalidEndDate}
                            disabled={endSettings.disabled}
                            width={endSettings.width}
                            inputSize={inputSize}
                        />
                    </div>
                </div>
            </div>
            {invalidStartDate && !invalidEndDate && (
                <span className={styles.errorMessage}>Start date is required</span>
            )}
            {invalidEndDate && !invalidStartDate && (
                <span className={styles.errorMessage}>End date is required</span>
            )}
            {invalidStartDate && invalidEndDate && (
                <span className={styles.errorMessage}>Start date and End date are required</span>
            )}
            {invalidDateRange && (
                <span className={styles.errorMessage}>{invalidDateMessage || 'Date range is invalid'}</span>
            )}
        </div>
    )
});

export default CustomDateRangeInput;